<template>
    <transition name="fade-in" mode="out-in">
        <AppSpinner v-if="isLoadingHome" />
    </transition>
    <main class="home">
        <template v-if="data">
            <ContainerHomeHero v-bind="data" />
            <SliderStock :data="stock" v-if="stock" />
            <ContainerHomeBoatModels v-bind="{ ...boatData }" v-if="boatData" />
            <ContainerHomeShowRoom v-bind="data" />
            <ContainerHomePosts :posts="posts.data" v-if="posts?.data" />
            <ContainerHomeSocials />
        </template>
    </main>
</template>

<script setup>
import { computed } from 'vue'
import { useQuery } from '@composables/useQuery'

const { data, isLoading } = useQuery('home', '/home?populate=*')
// const { data: boatData, isLoading: loadingBoatData } = useQuery('boatSlider', '/boat-slider?populate=cta&populate=boat_slide.image')
const { data: boatData, isLoading: loadingBoatData } = useQuery('boatSlider', '/boat-slider?populate=cta&populate=boat_slide.image')
const { data: stock, isLoading: loadingStock } = useQuery('stock', 'https://api.melbournemalibu.com.au/get-boats')
const { data: posts, isLoading: loadingPosts } = useQuery('posts', '/posts?populate=*')

const isLoadingHome = computed(() => {
    return isLoading.value || loadingBoatData.value || loadingStock.value || loadingPosts.value
})
</script>
